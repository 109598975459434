import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import Button from "../components/Button";
import Layout from "../components/Layout";
import Section from "../components/Section";
import TitleBar from "../components/TitleBar";
import Callout from "../components/Callout";
import Contributing from "../components/Contributing";
import FooterCta from "../components/FooterCta";

const About = () => {
  return (
    <Layout title="About Squeak!">
      <TitleBar title="About">
        <Button url="https://squeak.cloud/signup">
            Try Squeak! Cloud
        </Button>
      </TitleBar>
      
      <article>
        <Section id="about" description="Squeak! is an open source toolkit for fostering an in-house community.">
          <Callout className="-mt-4">
            <p className="text-base">
              Squeak! is{" "}
              <a
                href="https://github.com/posthog/squeak"
                target="_blank"
                rel="noreferrer"
                className=""
              >
                open source
              </a>{" "}
              and maintained by{" "}
              <a
                href="https://posthog.com"
                target="_blank"
                rel="noreferrer"
                className=""
              >
                PostHog
              </a>
              .
            </p>
          </Callout>

          <h3>How Squeak! got started</h3>
          <p>
            When <a href="https://posthog.com">PostHog</a> took off, our
            engineers quickly got flooded with questions in our Slack
            community.
          </p>
          <p>
            These questions would disappear, and weren’t indexed by Google.
            All that work would disappear into nothing. Like a sand mandala.
            But in a bad way.
          </p>
          <p>
            If a user doesn't ask a question directly, the path to finding an answer to a product question often leads users to third-party developer forums. This usually happens when a user can’t find a solution within a product’s own docs or knowledge base.
          </p>

          <h3>The in-house community model</h3>
          
          <p>Companies like Shopify, Webflow, and Figma created successful, in-house communities by offering resources around their core product. Meaning, they heavily invested into the entire customer experience – and outranked even developer forums because of their usefulness.</p>

          <p>But these communities were duct taped together by integrating third-party solutions. These services require extensive customization, send data to third parties, and can be expensive.</p>

          <h3>So why Squeak!?</h3>

          <p>PostHog started Squeak! as a productized version of the tools we use internally to build our own community. We outgrew using Slack as a community platform and wanted that content to be accessible to everyone.</p>

          <p>Because we built these tools to align with our brand values, it’s led to a better customer experience, developed a more loyal community, and grown our brand stickiness.</p>

          <p>We’ll continue to build these tools to solve our own customers needs, and we invite you to use them to solve yours.</p>

          <h3>What's next?</h3>
          <p>The roadmap lives on GitHub. We welcome your <a href="https://github.com/orgs/PostHog/projects/40"
            target="_blank"
            rel="noreferrer">feedback</a>.</p>
          <p>
            <a 
              href="https://github.com/orgs/PostHog/projects/40" 
              target="_blank"
              rel="noreferrer" 
              className="external-link after:content-['_↗']">
                Browse the roadmap on GitHub
            </a>
          </p>

          <a href="https://github.com/orgs/PostHog/projects/40">
            <StaticImage
              width={596}
              src="../images/roadmap.png"
              alt="Squeak! roadmap project board"
              placeholder="blurred"
            />
          </a>
        </Section>

        <Contributing />

      </article>

      <FooterCta 
        title="Well if you've made it this far..."
        description="Have you considered just giving it a try? It's free."
      />
    </Layout>
  );
};

export default About;
