import React from "react";

export default function Callout({ linkLabel, linkURL, children, className = ""}) {
  return (
    <div
      className={`rounded mb-8 py-4 px-8 flex flex-col text-base md:items-center md:flex-row justify-between bg-black bg-opacity-5 rounded bg-opacity-5 ${className}`}
    >
        <div className="flex-grow">
            {children}
        </div>
        {linkLabel && (
          <aside className="flex-shrink md:ml-8">
            <a
              href={linkURL}
              className="inline-flex py-2 px-4 whitespace-nowrap -ml-4 md:ml-0"
              target="_blank"
              rel="noreferrer"
            >{linkLabel}</a>
          </aside>
        )}
    </div>
  );
}
