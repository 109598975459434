import React from "react";
import Section from "../components/Section";

export default function Contributing({ title, description}) {
  return (
    <Section id="contributing" className="border-t border-dashed border-gray px-4 pt-8 pb-0">
      <h3>Contributing</h3>
      <p>
        If you’re interested in helping make Squeak! better - or are
        interested in building a plugin that works with Squeak!, please see
        the{" "}
        <a
          href="https://github.com/PostHog/squeak/wiki/local-development"
          target="_blank"
          rel="noreferrer"
        >
          Local development
        </a>{" "}
        section of the{" "}
        <a
          href="https://github.com/PostHog/squeak/wiki"
          target="_blank"
          rel="noreferrer"
        >
          wiki
        </a>
        .{" "}
      </p>
  </Section>
  );
}
