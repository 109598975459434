import React from "react";
import Section from "../components/Section";
import Button from "../components/Button";

export default function FooterCta({ title, description}) {
  return (
    <div className="border-t border-dashed border-gray pt-8 pb-0">

      <Section id="you-should-signup" className="!mb-0">
          <h3 className="mb-2">{title}</h3>
          <h4 className="mb-4">{description}</h4>

          <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4 mb-20">
            <Button url="https://squeak.cloud/signup" className="min-w-40">
              Try Squeak! Cloud
            </Button>
            <div className="relative">
              <Button
                url="https://github.com/posthog/squeak"
                className="border-2 border-solid border-gray bg-transparent !text-black min-w-40 w-full md:w-auto"
              >
                Browse on GitHub
              </Button>
            </div>
          </div>
      </Section>
    </div>
  );
}
